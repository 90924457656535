const tabContainer = document.querySelector('[data-tabs]');
const tabs = document.querySelectorAll('[data-tab]');

[...tabs].forEach((tab) => {
    tab.addEventListener('click', (event) => {
        event.preventDefault();
        const target = document.getElementById(tab.dataset.tab);

        if (!target) {
            return;
        }

        const scrollTop = target.getBoundingClientRect().top + window.scrollY
        const scrollOffset = tabContainer.offsetHeight + 20;

        window.scrollTo(0, scrollTop - scrollOffset);
    })
});
