import './photoswipe';
import Macy from 'macy';

(() => {
    const galleries = document.querySelectorAll('[data-gallery]');

    if (!galleries.length) {
        return;
    }

    [...galleries].forEach(gallery => {
        const macy = new Macy({
            container: gallery,
            trueOrder: true,
            waitForImages: true,
            columns: 3,
            margin: 20,
            breakAt: {
                940: 2,
            }
        });
    });
})();
