import querystring from 'querystring';

import PhotoSwipe from 'photoswipe';
import 'photoswipe/dist/photoswipe.css';
import PhotoSwipeDefaultUI from 'photoswipe/dist/photoswipe-ui-default';
import 'photoswipe/dist/default-skin/default-skin.css';
import getThumbBoundsFn from './getThumbBoundsFn';


/**
 * Docs: http://photoswipe.com/documentation
 */

const initPhotoSwipe = () => {
    const imageElements = document.querySelectorAll('.gallery-item');

    let items = [];

    const errorMsg = `
        <div class="pswp__error-msg">
            <a href="%url%" target="_blank">De afbeelding</a> kon niet worden geladen.
        </div>`;

    const defaultOptions = {
        getThumbBoundsFn,
        index: 0,
        showHideOpacity: false,
        shareEl: false,
        errorMsg,
    };

    const getNaturalDimension = image => ({
        w: image.naturalWidth,
        h: image.naturalHeight,
    });

    const getClientDimension = image => ({
        w: image.clientWidth,
        h: image.clientHeight,
    });

    const getDimensionFullImage = (url) => {
        const image = new Image();
        const promise = new Promise((resolve) => {
            image.addEventListener('load', function fetchSize() {
                resolve(getNaturalDimension(this));
            });
        });
        image.src = url;
        return promise;
    };

    const isExpandable = (thumbnailDimensions, fullDimensions, factor = 0.1) => {
        const widhtDifference = (fullDimensions.w * (1 - factor)) - thumbnailDimensions.w;
        const heightDifference = (fullDimensions.h * (1 - factor)) - thumbnailDimensions.h;
        return widhtDifference > 0 || heightDifference > 0;
    };

    const parseThumbnailElement = element => (
        new Promise((resolve) => {
            const image = element.querySelector('img');

            let title = '';
            const captionElement = element.querySelector('.a-figcaption');
            if (captionElement) {
                title = captionElement.innerText;
            }

            let imageObject = {
                element,
                title,
                expandable: true,
                thumbnail: {
                    src: image.src,
                    dimension: getClientDimension(image),
                },
                full: null,
            };

            const sourceFull = image.dataset.fullSrc;
            if (sourceFull) {
                getDimensionFullImage(sourceFull)
                    .then((dimension) => {
                        imageObject = {
                            ...imageObject,
                            full: {
                                src: sourceFull,
                                dimension,
                            },
                            expandable: true,
                        };

                        resolve(imageObject);
                    });
            } else {
                resolve(imageObject);
            }
        })
    );

    const markExpandable = (imageObject) => {
        imageObject.element.classList.add('-can-expand');
    };

    const openPhotoSwipe = (index, disableAnimation = false) => {
        const pswpElement = document.querySelectorAll('.pswp')[0];

        if (!pswpElement) {
            return;
        }

        const options = { ...defaultOptions };

        if (index > -1) {
            options.index = parseInt(index, 10) - 1;
        }

        if (disableAnimation) {
            options.showAnimationDuration = 0;
        }

        const gallery = new PhotoSwipe(
            pswpElement,
            PhotoSwipeDefaultUI,
            items,
            options,
        );

        gallery.init();
    };

    // find nearest parent element
    const closest = (el, fn) => (
        el && (fn(el) ? el : closest(el.parentNode, fn))
    );

    const onThumbnailsClick = (e) => {
        const event = e || window.event;
        /* eslint-disable no-unused-expressions */
        event.preventDefault ? event.preventDefault() : event.returnValue = false;
        /* eslint-enable */

        const eTarget = event.target || event.srcElement;

        // find root element of slide
        const clickedListItem = closest(eTarget, el => (
            el.tagName && el.tagName.toUpperCase() === 'FIGURE'
        ));

        if (!clickedListItem) {
            return;
        }

        const index = clickedListItem.dataset.pswpId;
        if (index >= 0) {
            openPhotoSwipe(index);
        }
    };

    const photoswipeParseHash = () => (
        querystring.parse(window.location.hash.substring(1))
    );

    Promise.all(Array.from(imageElements).map(parseThumbnailElement))
        .then((images) => {

            images.forEach(({ element }, index) => {
                const wrapper = element;
                wrapper.dataset.pswpId = index + 1;
            });

            items = images.map(imageObject => ({
                title: imageObject.title,
                src: imageObject.full.src,
                w: imageObject.full.dimension.w,
                h: imageObject.full.dimension.h,
            }));

            const expandables = images.filter(item => item.expandable);
            expandables.map(markExpandable);

            expandables.map(item => item.element)
                .forEach((element) => {
                    element.addEventListener('click', onThumbnailsClick);
                });
        });

    const hashData = photoswipeParseHash();
    if (hashData.pid && hashData.gid) {
        openPhotoSwipe(hashData.pid, true);
    }
};


document.addEventListener('DOMContentLoaded', () => {
    initPhotoSwipe();
});
