(() => {
  const languageSwitch = document.querySelector('[data-language-switch]');

  if (!languageSwitch) {
    return;
  }

  languageSwitch.addEventListener('change', () => {
    location.href = languageSwitch.value;
  })
})();
