(() => {
    const mobileToggle = document.querySelector('[data-mobile-toggle]');
    const mobileNav = document.querySelector('[data-mobile-nav]');
    const mobileHeader = document.querySelector('[data-mobile-header]');

    if (!mobileToggle) {
        return;
    }

    mobileToggle.addEventListener('click', () => {
        mobileNav.classList.toggle('is-open');
        mobileHeader.classList.toggle('is-mobile-nav-open');
        document.body.classList.toggle('no-scroll');
    });
})();