import Flickity from 'flickity';
import 'flickity/dist/flickity.css';

(() => {
  const hero = document.querySelector('.hero__container');

  if (!hero) {
    return;
  }

  const flky = new Flickity( hero, {
      cellAlign: 'left',
      cellSelector: '.hero',
      contain: true,
      wrapAround: true,
      pageDots: true,
      autoPlay: 5000,
      arrowShape: 'M6.8578 61.5392l59.0626 36.4072c4.273 2.6307 9.4393 2.74 13.82.2926 4.3806-2.447 6.9958-6.9043 6.9958-11.922V13.834C86.7362 6.2469 80.623.0412 73.1092 0h-.061c-2.3481 0-4.795.7361-7.0804 2.1307-1.8384 1.1216-2.4195 3.5213-1.2978 5.3595 1.1217 1.8387 3.5217 2.4196 5.3596 1.2979 1.069-.652 2.1129-.9893 3.0377-.989 2.835.0154 5.8703 2.4439 5.8703 6.0347v72.483c0 2.1525-1.1219 4.064-3.0008 5.1138-1.8791 1.0498-4.095 1.003-5.9256-.1242L10.9484 54.899c-1.7752-1.093-2.791-2.9154-2.7867-5.0002.0043-2.0846 1.0275-3.903 2.8125-4.9922l42.7026-26.1461c1.8367-1.1246 2.414-3.5252 1.2895-5.3618-1.1245-1.8367-3.525-2.414-5.3618-1.2894L6.907 38.2524C2.819 40.7464.3724 45.094.3626 49.8833.353 54.672 2.7818 59.0298 6.8578 61.5392z',
  });
})();
