export default function getThumbBoundsFn(index) {
    // find thumbnail element
    const thumbnail = document.querySelectorAll(`figure[data-pswp-id="${index + 1}"]`)[0];
    if (!thumbnail) return null;

    // get window scroll Y
    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
    // optionally get horizontal scroll

    // get position of element relative to viewport
    const rect = thumbnail.getBoundingClientRect();

    // w = width
    return {
        x: rect.left,
        y: rect.top + pageYScroll,
        w: rect.width,
    };

    // Good guide on how to get element coordinates:
    // http://javascript.info/tutorial/coordinates
}
